import { graphql } from 'gatsby';
import React from 'react';
import PageLayout from '../components/layout/PageLayout';


const Index = ({ data }) => {
  return (
    <PageLayout data={data.contentfulPage}/>
)
}


export const query = graphql`
  query{
    contentfulPage(slug: { eq: "home" }) {
      ...pages
    }
  }
`

export default Index
